<template>
  <div>
    <div>
      <div class="mainTitle">设备分类</div>
      <div class="content">
        智慧楼宇设备分类涵盖设备总览、设备总体情况分析、各系统设备报警趋势图，可规避各类问题，科学、快速、高效地制定决策。通过对设备总体情况数据分析，实现精益运作，提高工作的的可见性，优化工作流程，缩短传达时间，通过数据持续优化，提高决策能力，抵御
        运作风险。
      </div>
      <img src="@/assets/productcore/shebei1.png" alt="" />
    </div>
    <div>
      <div class="mainTitle">能耗指标</div>
      <div class="content">
        能耗指标智能平台的构建很大程度地提高了事企业数据整合以及数据分析的能力，很好地为管理者提供决策辅助。提高工作管理效率，根据市场数据分析，合理规划分配资源，调整业务结构，确保每个业务均可赢利。合理规划日能耗，缓解工作高峰期的能耗压力，提高客户
        的满意度，提高客户粘度。
      </div>
      <img src="@/assets/productcore/shebei2.png" alt="" />
    </div>
    <div>
      <div class="mainTitle">运维工单</div>
      <div class="content">
        运维管理智能平台包含维保分析、维保工单、点检巡检、其中维保工单涵盖内容、维保对象、维保类型、维保来源、设备位置、维保内容发起时间等。信息化系统数据，实现跨数据源整合取数，打通数据壁垒。实现对维保等业务的精准可视化分析，让管理层对经营状况了
        如指掌。
      </div>
      <img src="@/assets/productcore/shebei3.png" alt="" />
    </div>
    <div>
      <div class="mainTitle">报警提醒</div>
      <div class="content">
        报警提醒分析智能信息化系统，从报警分类分析、报警活动分析等方面全方位整合分析。分析报警数据，分析线上平台数据，利用折线图、文本、报表数据等为企事业单位提供智能分
        析。
      </div>
      <img src="@/assets/productcore/shebei4.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "device-manage",
};
</script>

<style scoped>
.mainTitle {
  font-size: 30px;
  color: #333;
  margin: 56px 0 40px;
  position: relative;
}
.mainTitle::after {
  content: "";
  width: 100px;
  height: 4px;
  background-color: #d7d7d7;
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translateX(-50%);
}
.content {
  color: #333;
  font-size: 21px;
  line-height: 30px;
  margin-bottom: 32px;
  letter-spacing: 0.2px;
}
img {
  width: 86%;
}
</style>